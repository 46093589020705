<template>
  <div class="mine page pp1 pp2 pp34">




	<div class="page-bg"></div>
    <div class="wrapper">
			
		
				<div class="top">
					<img  src="/img/goods/serch.png" class="serch"/>
				<!-- 	<van-field
					  v-model="username"
					  clearable
					  input-align="left"
					  class="input"
					  placeholder="번호 또는 이름"
					  input-class="custom-input-color"
					  @input="input"
					/>
					 -->
					<van-search
					 v-model="username"
					  
					  placeholder="번호 또는 이름"
					  @search="onSearch"
					  
					  class="input"
					  style="background-color: inherit !important; width: 100%;height: 100%; "
					/>
					
				</div>
				<div class="select">
					
						<select class="se" pointerenter="시" v-model="shi_index" @change="onSearch(1)">
							<option v-for="(rows,index) in shi" :value="index">{{rows.name}}</option>
						</select>
						
						<select class="se" pointerenter="구역" v-model="qu_index"  @change="onSearch(2)">
							<option  v-for="(rows,index) in shi[shi_index].chind" :value="index">{{rows.name}}</option>
							
						
						</select>
						<select class="se" pointerenter="나이" v-model="nian_index"  @change="onSearch(3)">
								<option v-for="(rows,index) in nian2" :value="rows">{{rows}}</option>
						</select>
					
				</div>
	
			<div class="gs" id="gs"  @scroll="handleScroll">
			
				<div  class="ps" >
						
							
							<div class="li" v-for="(rows,index) in left">
									
									<van-image
									    
									    class="img"
									   :src="rows.bg"
									  >
									    <template v-slot:loading>
									      <van-loading type="spinner" />
									    </template>
									  </van-image>
									
									
									<div class="xuhao">{{rows.xu}}</div>
									<div class="foot">
										<!-- <div class="a">{{rows.name}}</div> -->
										<div class="b">{{rows.nian}}세/{{rows.shen}}cm/{{rows.zoom}}kg</div>
										<div class="c" @click="tishi">예약 클릭</div>
										
									</div>
								</div>
							
							
							
					
					
					
				</div>
				
				<div  class="ps">
				
						<div class="li" v-for="(rows,index) in right">
							<van-image
							    
							    class="img"
							   :src="rows.bg"
							  >
							    <template v-slot:loading>
							      <van-loading type="spinner" />
							    </template>
							  </van-image>
							<div class="xuhao">{{rows.xu}}</div>
							<div class="foot">
								<!-- <div class="a">{{rows.name}}</div> -->
								<div class="b">{{rows.nian}}세/{{rows.shen}}cm/{{rows.zoom}}kg</div>
								<div class="c" @click="tishi">예약 클릭</div>
								
							</div>
						</div>
				
					
					
					
				</div>
				
		
				
				
			</div>
				
		
			

	</div>

</div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      show: false,
      payLoading: false,
      form: {
        bank_number: localStorage.getItem("bank_number"),
        bank_name: localStorage.getItem("bank_name"),
        amount: "",
      },
      menu_top: 40,
      isLoading: false,
	 shi:[
	 		  {
	 			 'name' :'시',
	 			  'chind':[
	 				  {
	 					 'name' :'구역',  
	 				  }
	 				
	 				  
	 				  
	 				  
	 				  
	 			  ]
	 		  			
	 		  },
	 		 {
	 			 'name' :'서울시',
	 			  'chind':[
	 				  {
	 					  'name' :'중구',  
	 				  },
	 				  {
	 					 'name' :'용산구',  
	 				  },
	 				  {
	 				  	 'name' :'성동구',  
	 				  },
	 				  {
	 				  	 'name' :'광진구',  
	 				  },
	 				  {
	 				  	 'name' :'동대문구',  
	 				  },
	 				  {
	 				  	 'name' :'중랑구',  
	 				  },
					  {
					  	 'name' :'중랑구',  
					  },
					  {
					  	 'name' :'성북구',  
					  }
					  ,
					  {
					  	 'name' :'강북구',  
					  },
					  
					  {
					  	 'name' :'도봉구',  
					  },
					  
					  {
					  	 'name' :'노원구',  
					  },
					  
					  {
					  	 'name' :'은평구',  
					  },
					  
					  {
					  	 'name' :'서대문구',  
					  },
					  
					  {
					  	 'name' :'마포구',  
					  },
					  
					  {
					  	 'name' :'양천구',  
					  },
					  
					  {
					  	 'name' :'강서구',  
					  },
					  
					  {
					  	 'name' :'구로구',  
					  },
					  
					  {
					  	 'name' :'금천구',  
					  },
					  
					  {
					  	 'name' :'영등포구',  
					  },
					  
					  {
					  	 'name' :'동작구',  
					  },
					  
					  {
					  	 'name' :'관악구',  
					  },
					  
					  {
					  	 'name' :'서초구',  
					  },
					  
					  {
					  	 'name' :'강남구',  
					  },
					  
					  {
					  	 'name' :'송파구',  
					  },
					  
					  {
					  	 'name' :'강동구',  
					  }
					
					
					
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
	 				  
	 				  
	 				  
	 				  
	 				  
	 			  ]
	 			
	 		 },
	 		  {
	 		  			 'name' :'부산광역시',
	 		  			  'chind':[
	 		  				  
	 		  				  {
	 							  'name' :'중구',  
	 						  },
	 		  				  {
	 		  				  	 'name' :'서구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'동구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'영도구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'부산진구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'동래구',  
	 		  				  },
							  {
	 		  				  	 'name' :'남구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'북구',  
	 		  				  },
	 		  				  {
	 		  				  	 'name' :'해운대구',  
	 		  				  },
							  {
	 		  				  	 'name' :'사하구',  
	 		  				  },
							  {
	 		  				  	 'name' :'금정구',  
	 		  				  },
							  {
	 		  				  	 'name' :'강서구',  
	 		  				  },
							  {
	 		  				  	 'name' :'연제구',  
	 		  				  },
							  {
	 		  				  	 'name' :'수영구',  
	 		  				  },
							  {
	 		  				  	 'name' :'사상구',  
	 		  				  }
	 		  				  ,
	 		  				  {
	 		  				  	 'name' :'기장군',  
	 		  				  }
	 		  				  
	 		  				  
	 		  				  
	 		  			  ]
	 		  			
	 		  },
			  {
			  			 'name' :'대구광역시',
			  			  'chind':[
			  				  
			  				  
			  				  {
			  				  	  'name' :'중구',  
			  				  },
							  {
							  	  'name' :'동구',  
							  },
							  {
							  	  'name' :'서구',  
							  },
							  {
							  	  'name' :'남구',  
							  },
							  {
							  	  'name' :'북구',  
							  },
							  {
							  	  'name' :'수성구',  
							  },
							  {
							  	  'name' :'달서구',  
							  },
							  
							  {
							  	  'name' :'달성군',  
							  },
							  {
							  	  'name' :'군위군',  
							  },
							  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'인천광역시',
			  			  'chind':[
			  				  
			  				  {
			  				  	  'name' :'중구',  
			  				  },
							  {
							  	  'name' :'동구',  
							  },
			  				  {
			  				  	  'name' :'미추홀구',  
			  				  },
							  {
							  	  'name' :'연수구',  
							  },
							  {
							  	  'name' :'남동구',  
							  },
							  {
							  	  'name' :'계양구',  
							  },
							  {
							  	  'name' :'서구',  
							  },
							  {
							  	  'name' :'강화군',  
							  },
							  {
							  	  'name' :'옹진군',  
							  }
			  			  ]
			  			
			  },
			  {
			  			 'name' :'광주광역시',
			  			  'chind':[
			  				  
			  				  {
			  				  	  'name' :'동구',  
			  				  },
							  {
							  	  'name' :'서구',  
							  },
							  {
							  	  'name' :'남구',  
							  },
							  {
							  	  'name' :'북구',  
							  },
							  {
							  	  'name' :'광산구',  
							  },
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'대전광역시',
			  			  'chind':[
			  				  
			  				  {
			  				  	  'name' :'동구',  
			  				  },
							  {
							  	  'name' :'중구',  
							  },
			  				  
							  {
							  	  'name' :'서구',  
							  },
							  
							  {
							  	  'name' :'유성구',  
							  },
							  {
							  	  'name' :'대덕구',  
							  },
							  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'울산광역시',
			  			  'chind':[
			  				  
			  				  {
			  				  	  'name' :'중구',  
			  				  },
							  
							  {
							  	  'name' :'남구',  
							  },
							  {
							  	  'name' :'동구',  
							  },
							  {
							  	  'name' :'북구',  
							  },
			  				  
							  {
							  	  'name' :'울주군',  
							  }
			  			  ]
			  			
			  },
			  {
			  			 'name' :'제주특별자치도',
			  			  'chind':[
			  				  {
			  				  	  'name' :'선택 시',  
			  				  },
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'강원도',
			  			  'chind':[
			  				  {
			  				  	  'name' :'춘천시',  
			  				  },
							  {
							  	  'name' :'동해시',  
							  },
							  {
							  	  'name' :'고성군',  
							  },
							  {
							  	  'name' :'횡성군',  
							  },
			  				  {
			  				  	  'name' :'홍천군',  
			  				  },
							  {
							  	  'name' :'화천군',  
							  },
							  {
							  	  'name' :'강릉시',  
							  },
							  {
							  	  'name' :'정선군',  
							  },
			  				  {
			  				  	  'name' :'인제군',  
			  				  },
			  				  
							  {
							  	  'name' :'영월군',  
							  },
							  
							  
							  {
							  	  'name' :'평창군',  
							  },
							  
							  {
							  	  'name' :'삼척시',  
							  },
							  
			  				  {
			  				  	  'name' :'속초시',  
			  				  },
							  {
							  	  'name' :'태백시',  
							  },
							  {
							  	  'name' :'철원군',  
							  },
							  {
							  	  'name' :'양양군',  
							  },
							  {
							  	  'name' :'양구군',  
							  },
							  {
							  	  'name' :'원주시',  
							  },
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'경상북도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'경상남도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'충청북도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'충청남도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'전라북도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
			  {
			  			 'name' :'전라남도',
			  			  'chind':[
			  				  
			  				  
			  				  
			  			  ]
			  			
			  },
	 		  {
	 		  			 'name' :'경기도',
	 		  			  'chind':[
	 		  				  
	 		  				  
	 		  				  
	 		  			  ]
	 		  			
	 		  },
	 		  
	 ],
	 	
	
	  nian:[
		  '나이',20,22,23,33,34,35,36,37
	  ],
	  nian2:[ '나이',20,22,23,33,34,35,36,37],
	   nian3:[],
	  
	  listdata:[
		
		  
	  ],
	  listdata2:[
	  		
	  		  
	  ],
	  left:[
		  
	  ],
	  right:[
		  
	  ],
	  shi_index:0,
	  qu_index:0,
	  nian_index:'나이',
	  username:'',
	  name2:[
	  		  '강나영',
	  		  '강소라',
	  		  '강지영',
	  		  '최미진',
	  		  '조수진',
	  		  '이채림',
	  		  '정서주',
	  		  '이주희',
	  		  '이수정',
	  		  '이소영',
	  		  '이미진',
	  		  '이미정',
	  		  '이미영',
	  		  '송민희',
	  		  '박지선',
	  		  '박주영',
	  		  '박은주',
	  		  '박미주',
	  		  '민국',
	  		  '김수진',
	  		  '김수영',
	  		  '김상미',
	  		  '김민정',
	  		  '김다희',
	  		  '김나영',
	  		  '강지원',
	  		  '강지영'
	  		  
	  ],
	  index_index:0,
	  page:10,
	   loading: true,
	        finished: false,
			 list: [],
			      loading: false,
			      finished: false,
				  sre:0,
				  srh:[]
    };
  },
  
  
  methods: {
	   scrollToTop() {
	       document.getElementById('gs').scrollTop=0;
	      },
	   handleScroll(event) {
	
	        const dialog = event.target;
			
	        if (dialog.scrollHeight - dialog.scrollTop >= dialog.clientHeight) {
	          // 当内容滑动到底部时，执行想要的操作
			  
			  if(this.sre==0)	
					this.init()
			   else
					this.init3()
	        }
	      },
	   onLoad() {
	        // 异步更新数据
	        setTimeout(() => {
	          for (let i = 0; i < 10; i++) {
	            this.list.push(this.list.length + 1);
	          }
	          // 加载状态结束
	          this.loading = false;
	  
	          // 数据全部加载完成
	          if (this.list.length >= 40) {
	            this.finished = true;
	          }
	        }, 500);
	      },
	 onLoad(){
		console.log('ss') 
	 },
	  onSearch(index){
		  this.sre=0
		   this.scrollToTop()
		  let nian=[]
		  //   Object.keys(this.nian).map(item=>{
				
		  // 	  if(item>0){
		  // 		  nian.push(this.nian[item])
		  // 	  }
		  //   })
		  
		  if(index<=2 ){
			this.nian2=[]
		  	if(this.shi_index==0 ){
		  		this.nian2 =this.nian
		  	}else{
		  		
				for(let i=Math.floor(Math.random() * 15) + 19;i<=Math.floor(Math.random() * 40) + 21;i++){
					this.nian2.push(i)
					
					
					
				}
				
			
				console.log(this.nian2)
				
				this.nian2.unshift(this.nian[0]);
				
				
		  	}
		  	
		  }else{
			  this.nian2=this.nian
			  
		  }
		  
		  
		 let username=this.username
		 
		  let data=this.listdata2
		
		   let pin=[]
		  	if(index==1){
				this.qu_index=0
				this.nian_index='나이'
			}
			 if(index==2){
				
				this.nian_index='나이'
			 }	
		
		// sheng:this.srh[i-1].sheng,
		//  shiqu:this.srh[i-1].shiqu,
		
		  if(this.shi_index>0)
		  {
		  			 
		  			Object.keys(data).map(item=>{
		  							
		  						 if(data[item].sheng==this.shi_index){
		  									
		  							 pin.push(data[item])
		  						 }
		  						 
		  			}) 
		  			
		  			 data=pin
		  			this.sre=1
		  					 
		  }
		  
		   
		 
		   if(this.qu_index>0)
		   {
		   			   pin=[]
		   			Object.keys(data).map(item=>{
		   							
		   						 if(data[item].shiqu==this.qu_index){
		   									
		   							 pin.push(data[item])
		   						 }
		   						 
		   			}) 
		   			
		   			 data=pin
		   			
		   			this.sre=1		 
		   }
		   
		  if(this.nian_index!='나이')
		  {
		  				 pin=[]; 
		  			Object.keys(data).map(item=>{
		  							
		  						 if(data[item].nian==this.nian_index){
		  									
		  							 pin.push(data[item])
		  						 }
		  						 
		  			}) 
		  			
		  			 data=pin
					 
		  		this.sre=1	 
		  }
		  
		  	
		  			  
		  
		  			  	
		  

		 
		  
		  if(username!=''){
			  pin=[]
			  
			 
		  	 Object.keys(data).map(item=>{
		
		  	 			 if( data[item].xu.indexOf(username)!=-1 ){
						
		  	 				 pin.push(data[item])
		  	 			 }
		  	 			 
		  	 })
			 
			 data=pin
			
			 this.sre=1
			 
		   }
		   
		 

		if(data.length){
			this.srh=data
			this.index_index=0
			this.left=[]
			this.right=[]
			
			this.init3()
		}else{
			this.index_index=0
			this.left=[]
			this.right=[]
			this.srh=[]
		}
	
				 
		//   this.left=[]
		//      this.right=[]
		    
		// Object.keys(data).map(item=>{
		  
		//   		index=Number(item)+1
		  			
		//   		 	//this.listdata[item].xu=index<=10?('00'+index):'0'+(index+1)
		//   		if(item%2==0){
		//   			this.left.push(data[item])
		//   		}else{
		//   			this.right.push(data[item])
		//   		}	   	
		// })
		  
		 
		 
	  },
	   shuffle1(arr) {
	      return arr.sort(() => 0.5 - Math.random())
	  },
tishi(){
	 this.$toast("번호를 기억해두시고 상담원에게 연락하셔서 예약해주세요");
},
	
	  back(){
		  this.$router.push({
		    path: "./Mine",
		  });
	  },
    pay() {
      if (!this.form.amount) return this.$toast("금액을 입력해주세요.");
      this.payLoading = true;
      this.$http({
        method: "post",
        data: this.form,
        url: "recharge_apply",
      })
        .then((res) => {
          if (res.code === 200) {
            this.show = false;
            this.$toast("검토를 위해 제출됨");
          } else {
            this.show = false;
            this.$toast("고객 서비스에 문의하십시오");
          }
        })
        .catch(() => {
          this.show = false;
          this.$toast("고객 서비스에 문의하십시오");
        });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.$toast("다운로드 중");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    exit() {
      this.$toast("작업 목록을 작성하고 입력하세요.");
    },
    showSetting() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Setting",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    toNotice() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Notice",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.getUserInfo();
          this.$toast("새로고침 성공");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Infomation",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    doPay() {
      if (localStorage.getItem("token")) {
        this.show = true;
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
      // this.$toast("Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail("결제카드를 설정해주세요");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("지원을 받으려면 고객 서비스에 문의하세요");
      }
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log("userInfo===🚀===>", this.userInfo);
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("오프라인 계정");
            localStorage.clear();
            this.$router.push({
              path: "/Login",
            });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
	init2(){
		let index=0
		let xu=0
		let shen=0
		let xia=0
			for(let i=0;i<=268;i++){
				
				if(i<=9){
					xu='00'+(i+1)
					
					
				}
				
				
				if(i>=9 && i<=99){
					xu='0'+(i+1)
					
				}
				
				if(i>=99 && i<=999){
					xu=(i+1)
					
				}
				
				
				
				this.listdata2[i]={
					
							  'name':this.name2[i%27] ,
							  'bg':'/img/goods/zong/'+(i+1)+'.PNG',
							  'nian':0,
							'shen':0 ,
							'zoom':0,
						sheng:Math.floor(Math.random() * 10) + 1,
						shiqu:Math.floor(Math.random() * 6) + 1,
							  xu:xu.toString()
							  
				}


				}
	
		
		
		this.datap()
	},
	datap(){
		
		
			this.listdata2[0].nian=23
		this.listdata2[0].shen=162
		this.listdata2[0].zoom=47
			
		this.listdata2[1].nian=21
		this.listdata2[1].shen=166
		this.listdata2[1].zoom=50
		
		this.listdata2[2].nian=26
		this.listdata2[2].shen=171
		this.listdata2[2].zoom=53

		this.listdata2[3].nian=24
		this.listdata2[3].shen=165
		this.listdata2[3].zoom=49
		
		this.listdata2[4].nian=31
		this.listdata2[4].shen=170
		this.listdata2[4].zoom=53
		
		this.listdata2[5].nian=22
		this.listdata2[5].shen=164
		this.listdata2[5].zoom=50
		
		this.listdata2[6].nian=29
		this.listdata2[6].shen=168
		this.listdata2[6].zoom=51
		
		this.listdata2[7].nian=28
		this.listdata2[7].shen=172
		this.listdata2[7].zoom=52
		
		this.listdata2[8].nian=20
		this.listdata2[8].shen=163
		this.listdata2[8].zoom=45
		
		this.listdata2[9].nian=23
		this.listdata2[9].shen=166
		this.listdata2[9].zoom=48
		
		this.listdata2[10].nian=31
		this.listdata2[10].shen=170
		this.listdata2[10].zoom=54
		
		this.listdata2[11].nian=27
		this.listdata2[11].shen=168
		this.listdata2[11].zoom=51
		
		this.listdata2[12].nian=23
		this.listdata2[12].shen=171
		this.listdata2[12].zoom=52
		
		this.listdata2[13].nian=25
		this.listdata2[13].shen=166
		this.listdata2[13].zoom=49
		
		this.listdata2[14].nian=28
		this.listdata2[14].shen=175
		this.listdata2[14].zoom=55
		
		this.listdata2[15].nian=27
		this.listdata2[15].shen=174
		this.listdata2[15].zoom=55
		
		this.listdata2[16].nian=30
		this.listdata2[16].shen=168
		this.listdata2[16].zoom=53
		
		this.listdata2[17].nian=25
		this.listdata2[17].shen=165
		this.listdata2[17].zoom=49
		
		this.listdata2[18].nian=29
		this.listdata2[18].shen=163
		this.listdata2[18].zoom=48
		
		this.listdata2[19].nian=34
		this.listdata2[19].shen=170
		this.listdata2[19].zoom=52
		
		this.listdata2[20].nian=24
		this.listdata2[20].shen=162
		this.listdata2[20].zoom=46		
				
		this.listdata2[21].nian=21
		this.listdata2[21].shen=167
		this.listdata2[21].zoom=49
		
		this.listdata2[22].nian=23
		this.listdata2[22].shen=162
		this.listdata2[22].zoom=48
		
		this.listdata2[23].nian=27
		this.listdata2[23].shen=171
		this.listdata2[23].zoom=51

		this.listdata2[24].nian=30
		this.listdata2[24].shen=169
		this.listdata2[24].zoom=50

		this.listdata2[25].nian=32
		this.listdata2[25].shen=173
		this.listdata2[25].zoom=52

		this.listdata2[26].nian=25
		this.listdata2[26].shen=163
		this.listdata2[26].zoom=49

		this.listdata2[27].nian=20
		this.listdata2[27].shen=162
		this.listdata2[27].zoom=43

		this.listdata2[28].nian=30
		this.listdata2[28].shen=169
		this.listdata2[28].zoom=50

		this.listdata2[29].nian=32
		this.listdata2[29].shen=170
		this.listdata2[29].zoom=53

		this.listdata2[30].nian=32
		this.listdata2[30].shen=166
		this.listdata2[30].zoom=51

		this.listdata2[31].nian=26
		this.listdata2[31].shen=168
		this.listdata2[31].zoom=46

		this.listdata2[32].nian=27
		this.listdata2[32].shen=170
		this.listdata2[32].zoom=53

        this.listdata2[33].nian=21
		this.listdata2[33].shen=174
		this.listdata2[33].zoom=51

		this.listdata2[34].nian=24
		this.listdata2[34].shen=169
		this.listdata2[34].zoom=49

		this.listdata2[35].nian=21
		this.listdata2[35].shen=170
		this.listdata2[35].zoom=51

		this.listdata2[36].nian=22
		this.listdata2[36].shen=158
		this.listdata2[36].zoom=52

		this.listdata2[37].nian=23
		this.listdata2[37].shen=163
		this.listdata2[37].zoom=44

		this.listdata2[38].nian=24
		this.listdata2[38].shen=164
		this.listdata2[38].zoom=45

		this.listdata2[39].nian=25
		this.listdata2[39].shen=165
		this.listdata2[39].zoom=46

		this.listdata2[40].nian=25
		this.listdata2[40].shen=166
		this.listdata2[40].zoom=47

		this.listdata2[41].nian=27
		this.listdata2[41].shen=168
		this.listdata2[41].zoom=49

		this.listdata2[42].nian=28
		this.listdata2[42].shen=169
		this.listdata2[42].zoom=50
		
		this.listdata2[43].nian=29
		this.listdata2[43].shen=170
		this.listdata2[43].zoom=51
	
		this.listdata2[44].nian=30
		this.listdata2[44].shen=169
		this.listdata2[44].zoom=50

		this.listdata2[45].nian=32
		this.listdata2[45].shen=173
		this.listdata2[45].zoom=52

		this.listdata2[46].nian=25
		this.listdata2[46].shen=163
		this.listdata2[46].zoom=49

		this.listdata2[47].nian=20
		this.listdata2[47].shen=162
		this.listdata2[47].zoom=43

		this.listdata2[48].nian=30
		this.listdata2[48].shen=169
		this.listdata2[48].zoom=50

		this.listdata2[49].nian=20
		this.listdata2[49].shen=169
		this.listdata2[49].zoom=50

		this.listdata2[50].nian=21
		this.listdata2[50].shen=162
		this.listdata2[50].zoom=51

		this.listdata2[51].nian=25
		this.listdata2[51].shen=168
		this.listdata2[51].zoom=50

        this.listdata2[52].nian=29
		this.listdata2[52].shen=168
		this.listdata2[52].zoom=50

        this.listdata2[53].nian=23
		this.listdata2[53].shen=165
		this.listdata2[53].zoom=51

		this.listdata2[54].nian=23
		this.listdata2[54].shen=165
		this.listdata2[54].zoom=54

                this.listdata2[55].nian=24
		this.listdata2[55].shen=166
		this.listdata2[55].zoom=54

		this.listdata2[56].nian=25
		this.listdata2[56].shen=167
		this.listdata2[56].zoom=51

		this.listdata2[57].nian=26
		this.listdata2[57].shen=167
		this.listdata2[57].zoom=42

		this.listdata2[58].nian=27
		this.listdata2[58].shen=168
		this.listdata2[58].zoom=43

		this.listdata2[59].nian=28
		this.listdata2[59].shen=169
		this.listdata2[59].zoom=54

		this.listdata2[60].nian=29
		this.listdata2[60].shen=170
		this.listdata2[60].zoom=55

		this.listdata2[61].nian=30
		this.listdata2[61].shen=171
		this.listdata2[61].zoom=46

		this.listdata2[62].nian=31
		this.listdata2[62].shen=172
		this.listdata2[62].zoom=47

		this.listdata2[63].nian=32
		this.listdata2[63].shen=165
		this.listdata2[63].zoom=51

		this.listdata2[64].nian=32
		this.listdata2[64].shen=169
		this.listdata2[64].zoom=50

		this.listdata2[65].nian=27
		this.listdata2[65].shen=171
		this.listdata2[65].zoom=51
		
		this.listdata2[66].nian=23
		this.listdata2[66].shen=162
		this.listdata2[66].zoom=48
		
		this.listdata2[67].nian=27
		this.listdata2[67].shen=171
		this.listdata2[67].zoom=51

		this.listdata2[68].nian=30
		this.listdata2[68].shen=169
		this.listdata2[68].zoom=50

		this.listdata2[69].nian=32
		this.listdata2[69].shen=173
		this.listdata2[69].zoom=52

		this.listdata2[70].nian=25
		this.listdata2[70].shen=163
		this.listdata2[70].zoom=49

		this.listdata2[71].nian=20
		this.listdata2[71].shen=162
		this.listdata2[71].zoom=43

		this.listdata2[72].nian=30
		this.listdata2[72].shen=166
		this.listdata2[72].zoom=50

		this.listdata2[73].nian=31
		this.listdata2[73].shen=165
		this.listdata2[73].zoom=50

		this.listdata2[74].nian=30
		this.listdata2[74].shen=168
		this.listdata2[74].zoom=50

		this.listdata2[75].nian=32
		this.listdata2[75].shen=164
		this.listdata2[75].zoom=52

		this.listdata2[76].nian=20
		this.listdata2[76].shen=166
		this.listdata2[76].zoom=52

		this.listdata2[77].nian=21
		this.listdata2[77].shen=162
		this.listdata2[77].zoom=42

		this.listdata2[78].nian=22
		this.listdata2[78].shen=163
		this.listdata2[78].zoom=43

		this.listdata2[79].nian=20
		this.listdata2[79].shen=168
		this.listdata2[79].zoom=44

		this.listdata2[80].nian=23
		this.listdata2[80].shen=166
		this.listdata2[80].zoom=55

		this.listdata2[81].nian=24
		this.listdata2[81].shen=166
		this.listdata2[81].zoom=52

		this.listdata2[82].nian=25
		this.listdata2[82].shen=166
		this.listdata2[82].zoom=54

		this.listdata2[83].nian=26
		this.listdata2[83].shen=168
		this.listdata2[83].zoom=55

		this.listdata2[84].nian=27
		this.listdata2[84].shen=169
		this.listdata2[84].zoom=53

		this.listdata2[85].nian=28
		this.listdata2[85].shen=166
		this.listdata2[85].zoom=44

		this.listdata2[86].nian=27
		this.listdata2[86].shen=171
		this.listdata2[86].zoom=51

		this.listdata2[87].nian=21
		this.listdata2[87].shen=167
		this.listdata2[87].zoom=49
		
		this.listdata2[88].nian=23
		this.listdata2[88].shen=162
		this.listdata2[88].zoom=48
		
		this.listdata2[89].nian=27
		this.listdata2[89].shen=171
		this.listdata2[89].zoom=51

		this.listdata2[90].nian=30
		this.listdata2[90].shen=169
		this.listdata2[90].zoom=50

		this.listdata2[91].nian=32
		this.listdata2[91].shen=173
		this.listdata2[91].zoom=52

		this.listdata2[92].nian=25
		this.listdata2[92].shen=163
		this.listdata2[92].zoom=49

		this.listdata2[93].nian=20
		this.listdata2[93].shen=162
		this.listdata2[93].zoom=43

		this.listdata2[94].nian=30
		this.listdata2[94].shen=169
		this.listdata2[94].zoom=52

		this.listdata2[95].nian=31
		this.listdata2[95].shen=169
		this.listdata2[95].zoom=55

		this.listdata2[96].nian=32
		this.listdata2[96].shen=168
		this.listdata2[96].zoom=52

		this.listdata2[97].nian=32
		this.listdata2[97].shen=166
		this.listdata2[97].zoom=54

		this.listdata2[98].nian=20
		this.listdata2[98].shen=165
		this.listdata2[98].zoom=52

		this.listdata2[99].nian=21
		this.listdata2[99].shen=168
		this.listdata2[99].zoom=52

		this.listdata2[100].nian=22
		this.listdata2[100].shen=166
		this.listdata2[100].zoom=51

		this.listdata2[101].nian=23
		this.listdata2[101].shen=165
		this.listdata2[101].zoom=52

		this.listdata2[102].nian=24
		this.listdata2[102].shen=166
		this.listdata2[102].zoom=54

		this.listdata2[103].nian=25
		this.listdata2[103].shen=167
		this.listdata2[103].zoom=53

		this.listdata2[104].nian=26
		this.listdata2[104].shen=166
		this.listdata2[104].zoom=52

		this.listdata2[105].nian=27
		this.listdata2[105].shen=168
		this.listdata2[105].zoom=51

		this.listdata2[106].nian=28
		this.listdata2[106].shen=169
		this.listdata2[106].zoom=54

		this.listdata2[107].nian=30
		this.listdata2[107].shen=172
		this.listdata2[107].zoom=53

		this.listdata2[108].nian=31
		this.listdata2[108].shen=171
		this.listdata2[108].zoom=51

		this.listdata2[109].nian=21
		this.listdata2[109].shen=167
		this.listdata2[109].zoom=49
		
		this.listdata2[110].nian=23
		this.listdata2[110].shen=162
		this.listdata2[110].zoom=48
		
		this.listdata2[111].nian=27
		this.listdata2[111].shen=171
		this.listdata2[111].zoom=51

		this.listdata2[112].nian=30
		this.listdata2[112].shen=169
		this.listdata2[112].zoom=50

		this.listdata2[113].nian=32
		this.listdata2[113].shen=173
		this.listdata2[113].zoom=52

		this.listdata2[114].nian=25
		this.listdata2[114].shen=163
		this.listdata2[114].zoom=49

		this.listdata2[115].nian=20
		this.listdata2[115].shen=162
		this.listdata2[115].zoom=43

		this.listdata2[116].nian=31
		this.listdata2[116].shen=169
		this.listdata2[116].zoom=50

		this.listdata2[117].nian=20
		this.listdata2[117].shen=162
		this.listdata2[117].zoom=42

		this.listdata2[118].nian=21
		this.listdata2[118].shen=163
		this.listdata2[118].zoom=43

		this.listdata2[119].nian=22
		this.listdata2[119].shen=164
		this.listdata2[119].zoom=44

		this.listdata2[120].nian=23
		this.listdata2[120].shen=165
		this.listdata2[120].zoom=52

		this.listdata2[121].nian=24
		this.listdata2[121].shen=166
		this.listdata2[121].zoom=53

		this.listdata2[122].nian=25
		this.listdata2[122].shen=167
		this.listdata2[122].zoom=51

		this.listdata2[123].nian=26
		this.listdata2[123].shen=168
		this.listdata2[123].zoom=53

		this.listdata2[124].nian=27
		this.listdata2[124].shen=169
		this.listdata2[124].zoom=51

		this.listdata2[125].nian=28
		this.listdata2[125].shen=172
		this.listdata2[125].zoom=50

		this.listdata2[126].nian=29
		this.listdata2[126].shen=173
		this.listdata2[126].zoom=52

		this.listdata2[127].nian=30
		this.listdata2[127].shen=174
		this.listdata2[127].zoom=53

		this.listdata2[128].nian=31
		this.listdata2[128].shen=174
		this.listdata2[128].zoom=55

		this.listdata2[129].nian=32
		this.listdata2[129].shen=172
		this.listdata2[129].zoom=51

		this.listdata2[130].nian=27
		this.listdata2[130].shen=171
		this.listdata2[130].zoom=51

		this.listdata2[131].nian=21
		this.listdata2[131].shen=167
		this.listdata2[131].zoom=49
		
		this.listdata2[132].nian=23
		this.listdata2[132].shen=162
		this.listdata2[132].zoom=48
		
		this.listdata2[133].nian=27
		this.listdata2[133].shen=171
		this.listdata2[133].zoom=51

		this.listdata2[134].nian=30
		this.listdata2[134].shen=169
		this.listdata2[134].zoom=51

		this.listdata2[135].nian=32
		this.listdata2[135].shen=173
		this.listdata2[135].zoom=52

		this.listdata2[136].nian=25
		this.listdata2[136].shen=163
		this.listdata2[136].zoom=49

		this.listdata2[137].nian=20
		this.listdata2[137].shen=162
		this.listdata2[137].zoom=43

		this.listdata2[138].nian=20
		this.listdata2[138].shen=169
		this.listdata2[138].zoom=51

		this.listdata2[139].nian=32
		this.listdata2[139].shen=169
		this.listdata2[139].zoom=53

		this.listdata2[140].nian=21
		this.listdata2[140].shen=164
		this.listdata2[140].zoom=42

		this.listdata2[141].nian=22
		this.listdata2[141].shen=165
		this.listdata2[141].zoom=43

		this.listdata2[142].nian=23
		this.listdata2[142].shen=163
		this.listdata2[142].zoom=51

		this.listdata2[143].nian=24
		this.listdata2[143].shen=166
		this.listdata2[143].zoom=55

		this.listdata2[144].nian=25
		this.listdata2[144].shen=167
		this.listdata2[144].zoom=51

		this.listdata2[145].nian=26
		this.listdata2[145].shen=163
		this.listdata2[145].zoom=52

		this.listdata2[146].nian=27
		this.listdata2[146].shen=168
		this.listdata2[146].zoom=55

		this.listdata2[147].nian=28
		this.listdata2[147].shen=165
		this.listdata2[147].zoom=52

		this.listdata2[148].nian=29
		this.listdata2[148].shen=167
		this.listdata2[148].zoom=51

		this.listdata2[149].nian=30
		this.listdata2[149].shen=168
		this.listdata2[149].zoom=53

		this.listdata2[150].nian=20
		this.listdata2[150].shen=163
		this.listdata2[150].zoom=51

		this.listdata2[151].nian=21
		this.listdata2[151].shen=165
		this.listdata2[151].zoom=48

		this.listdata2[152].nian=27
		this.listdata2[152].shen=171
		this.listdata2[152].zoom=51

		this.listdata2[153].nian=21
		this.listdata2[153].shen=167
		this.listdata2[153].zoom=49
		
		this.listdata2[154].nian=23
		this.listdata2[154].shen=162
		this.listdata2[154].zoom=48
		
		this.listdata2[155].nian=27
		this.listdata2[155].shen=171
		this.listdata2[155].zoom=51

		this.listdata2[156].nian=30
		this.listdata2[156].shen=169
		this.listdata2[156].zoom=50

		this.listdata2[157].nian=32
		this.listdata2[157].shen=173
		this.listdata2[157].zoom=52

		this.listdata2[158].nian=25
		this.listdata2[158].shen=163
		this.listdata2[158].zoom=49

		this.listdata2[159].nian=20
		this.listdata2[159].shen=162
		this.listdata2[159].zoom=43

		this.listdata2[160].nian=31
		this.listdata2[160].shen=163
		this.listdata2[160].zoom=50

		this.listdata2[161].nian=32
		this.listdata2[161].shen=172
		this.listdata2[161].zoom=52

		this.listdata2[162].nian=31
		this.listdata2[162].shen=172
		this.listdata2[162].zoom=53

		this.listdata2[163].nian=20
		this.listdata2[163].shen=164
		this.listdata2[163].zoom=51

		this.listdata2[164].nian=21
		this.listdata2[164].shen=165
		this.listdata2[164].zoom=51

		this.listdata2[165].nian=22
		this.listdata2[165].shen=166
		this.listdata2[165].zoom=53

		this.listdata2[166].nian=23
		this.listdata2[166].shen=167
		this.listdata2[166].zoom=53

		this.listdata2[167].nian=24
		this.listdata2[167].shen=168
		this.listdata2[167].zoom=51

		this.listdata2[168].nian=26
		this.listdata2[168].shen=166
		this.listdata2[168].zoom=52

		this.listdata2[169].nian=31
		this.listdata2[169].shen=168
		this.listdata2[169].zoom=50

		this.listdata2[170].nian=32
		this.listdata2[170].shen=167
		this.listdata2[170].zoom=52

		this.listdata2[171].nian=32
		this.listdata2[171].shen=166
		this.listdata2[171].zoom=53

		this.listdata2[172].nian=30
		this.listdata2[172].shen=170
		this.listdata2[172].zoom=53

		this.listdata2[173].nian=22
		this.listdata2[173].shen=166
		this.listdata2[173].zoom=52

		this.listdata2[174].nian=27
		this.listdata2[174].shen=171
		this.listdata2[174].zoom=51

		this.listdata2[175].nian=21
		this.listdata2[175].shen=167
		this.listdata2[175].zoom=49
		
		this.listdata2[176].nian=23
		this.listdata2[176].shen=162
		this.listdata2[176].zoom=48
		
		this.listdata2[177].nian=27
		this.listdata2[177].shen=171
		this.listdata2[177].zoom=51

		this.listdata2[178].nian=31
		this.listdata2[178].shen=169
		this.listdata2[178].zoom=51

		this.listdata2[179].nian=32
		this.listdata2[179].shen=173
		this.listdata2[179].zoom=52

		this.listdata2[180].nian=25
		this.listdata2[180].shen=163
		this.listdata2[180].zoom=49

		this.listdata2[181].nian=20
		this.listdata2[181].shen=162
		this.listdata2[181].zoom=43

		this.listdata2[182].nian=32
		this.listdata2[182].shen=169
		this.listdata2[182].zoom=53

		this.listdata2[183].nian=32
		this.listdata2[183].shen=166
		this.listdata2[183].zoom=52

		this.listdata2[184].nian=31
		this.listdata2[184].shen=168
		this.listdata2[184].zoom=51

		this.listdata2[185].nian=31
		this.listdata2[185].shen=167
		this.listdata2[185].zoom=52

		this.listdata2[186].nian=20
		this.listdata2[186].shen=168
		this.listdata2[186].zoom=51

		this.listdata2[187].nian=21
		this.listdata2[187].shen=163
		this.listdata2[187].zoom=44

		this.listdata2[188].nian=24
		this.listdata2[188].shen=164
		this.listdata2[188].zoom=45

		this.listdata2[189].nian=23
		this.listdata2[189].shen=165
		this.listdata2[189].zoom=46

		this.listdata2[190].nian=24
		this.listdata2[190].shen=166
		this.listdata2[190].zoom=47

		this.listdata2[191].nian=25
		this.listdata2[191].shen=167
		this.listdata2[191].zoom=48

		this.listdata2[192].nian=26
		this.listdata2[192].shen=168
		this.listdata2[192].zoom=49

		this.listdata2[193].nian=27
		this.listdata2[193].shen=169
		this.listdata2[193].zoom=51

		this.listdata2[194].nian=28
		this.listdata2[194].shen=165
		this.listdata2[194].zoom=42

		this.listdata2[195].nian=29
		this.listdata2[195].shen=168
		this.listdata2[195].zoom=44

		this.listdata2[196].nian=27
		this.listdata2[196].shen=171
		this.listdata2[196].zoom=51

		this.listdata2[197].nian=21
		this.listdata2[197].shen=167
		this.listdata2[197].zoom=49
		
		this.listdata2[198].nian=23
		this.listdata2[198].shen=162
		this.listdata2[198].zoom=48
		
		this.listdata2[199].nian=27
		this.listdata2[199].shen=171
		this.listdata2[199].zoom=51

		this.listdata2[200].nian=31
		this.listdata2[200].shen=164
		this.listdata2[200].zoom=50

		this.listdata2[201].nian=32
		this.listdata2[201].shen=173
		this.listdata2[201].zoom=52

		this.listdata2[202].nian=25
		this.listdata2[202].shen=163
		this.listdata2[202].zoom=49

		this.listdata2[203].nian=20
		this.listdata2[203].shen=162
		this.listdata2[203].zoom=43

		this.listdata2[204].nian=32
		this.listdata2[204].shen=169
		this.listdata2[204].zoom=46

		this.listdata2[205].nian=31
		this.listdata2[205].shen=168
		this.listdata2[205].zoom=47

		this.listdata2[206  ].nian=32
		this.listdata2[206].shen=168
		this.listdata2[206].zoom=49

		this.listdata2[207].nian=30
		this.listdata2[207].shen=168
		this.listdata2[207].zoom=52

		this.listdata2[208].nian=20
		this.listdata2[208].shen=168
		this.listdata2[208].zoom=52

		this.listdata2[209].nian=21
		this.listdata2[209].shen=167
		this.listdata2[209].zoom=44

		this.listdata2[210].nian=22
		this.listdata2[210].shen=162
		this.listdata2[210].zoom=43

		this.listdata2[211].nian=23
		this.listdata2[211].shen=163
		this.listdata2[211].zoom=44

		this.listdata2[212].nian=24
		this.listdata2[212].shen=164
		this.listdata2[212].zoom=45

		this.listdata2[213].nian=25
		this.listdata2[213].shen=164
		this.listdata2[213].zoom=46

		this.listdata2[214].nian=26
		this.listdata2[214].shen=165
		this.listdata2[214].zoom=47

		this.listdata2[215].nian=29
		this.listdata2[215].shen=166
		this.listdata2[215].zoom=47

		this.listdata2[216].nian=28
		this.listdata2[216].shen=167
		this.listdata2[216].zoom=52

		this.listdata2[217].nian=29
		this.listdata2[217].shen=168
		this.listdata2[217].zoom=55

		this.listdata2[218].nian=27
		this.listdata2[218].shen=171
		this.listdata2[218].zoom=51

		this.listdata2[219].nian=21
		this.listdata2[219].shen=167
		this.listdata2[219].zoom=49
		
		this.listdata2[220].nian=23
		this.listdata2[220].shen=162
		this.listdata2[220].zoom=48
		
		this.listdata2[221].nian=27
		this.listdata2[221].shen=171
		this.listdata2[221].zoom=51

		this.listdata2[222].nian=30
		this.listdata2[222].shen=169
		this.listdata2[222].zoom=50

		this.listdata2[223].nian=32
		this.listdata2[223].shen=173
		this.listdata2[223].zoom=52

		this.listdata2[224].nian=25
		this.listdata2[224].shen=163
		this.listdata2[224].zoom=49

		this.listdata2[225].nian=20
		this.listdata2[225].shen=162
		this.listdata2[225].zoom=43

		this.listdata2[226].nian=31
		this.listdata2[226].shen=169
		this.listdata2[226].zoom=51

		this.listdata2[227].nian=32
		this.listdata2[227].shen=168
		this.listdata2[227].zoom=53

		this.listdata2[228].nian=32
		this.listdata2[228].shen=165
		this.listdata2[228].zoom=44

		this.listdata2[229].nian=31
		this.listdata2[229].shen=168
		this.listdata2[229].zoom=46

		this.listdata2[230].nian=25
		this.listdata2[230].shen=168
		this.listdata2[230].zoom=48

		this.listdata2[231].nian=24
		this.listdata2[231].shen=167
		this.listdata2[231].zoom=46

		this.listdata2[232].nian=26
		this.listdata2[232].shen=167
		this.listdata2[232].zoom=43

		this.listdata2[233].nian=26
		this.listdata2[233].shen=167
		this.listdata2[233].zoom=48

		this.listdata2[234].nian=22
		this.listdata2[234].shen=168
		this.listdata2[234].zoom=42

		this.listdata2[235].nian=29
		this.listdata2[235].shen=167
		this.listdata2[235].zoom=48

		this.listdata2[236].nian=28
		this.listdata2[236].shen=168
		this.listdata2[236].zoom=48

		this.listdata2[237].nian=26
		this.listdata2[237].shen=167
		this.listdata2[237].zoom=44

		this.listdata2[238].nian=21
		this.listdata2[238].shen=167
		this.listdata2[238].zoom=44

		this.listdata2[239].nian=28
		this.listdata2[239].shen=168
		this.listdata2[239].zoom=51

		this.listdata2[240].nian=27
		this.listdata2[240].shen=171
		this.listdata2[240].zoom=51

		this.listdata2[241].nian=21
		this.listdata2[241].shen=167
		this.listdata2[241].zoom=49
		
		this.listdata2[242].nian=23
		this.listdata2[242].shen=162
		this.listdata2[242].zoom=48
		
		this.listdata2[243].nian=27
		this.listdata2[243].shen=171
		this.listdata2[243].zoom=51

		this.listdata2[244].nian=30
		this.listdata2[244].shen=169
		this.listdata2[244].zoom=50

		this.listdata2[245].nian=32
		this.listdata2[245].shen=173
		this.listdata2[245].zoom=52

		this.listdata2[246].nian=25
		this.listdata2[246].shen=163
		this.listdata2[246].zoom=49

		this.listdata2[247].nian=20
		this.listdata2[247].shen=162
		this.listdata2[247].zoom=43

		this.listdata2[248].nian=32
		this.listdata2[248].shen=168
		this.listdata2[248].zoom=51

		this.listdata2[249].nian=32
		this.listdata2[249].shen=167
		this.listdata2[249].zoom=55

		this.listdata2[250].nian=31
		this.listdata2[250].shen=165
		this.listdata2[250].zoom=53

		this.listdata2[251].nian=31
		this.listdata2[251].shen=168
		this.listdata2[251].zoom=51

		this.listdata2[252].nian=32
		this.listdata2[252].shen=165
		this.listdata2[252].zoom=43

		this.listdata2[253].nian=20
		this.listdata2[253].shen=172
		this.listdata2[253].zoom=52

		this.listdata2[254].nian=21
		this.listdata2[254].shen=172
		this.listdata2[254].zoom=43

		this.listdata2[255].nian=22
		this.listdata2[255].shen=174
		this.listdata2[255].zoom=43

		this.listdata2[256].nian=23
		this.listdata2[256].shen=175
		this.listdata2[256].zoom=47

		this.listdata2[257].nian=24
		this.listdata2[257].shen=167
		this.listdata2[257].zoom=43

		this.listdata2[258].nian=25
		this.listdata2[258].shen=168
		this.listdata2[258].zoom=52

		this.listdata2[259].nian=26
		this.listdata2[259].shen=163
		this.listdata2[259].zoom=52

		this.listdata2[260].nian=27
		this.listdata2[260].shen=166
		this.listdata2[260].zoom=45

		this.listdata2[261].nian=28
		this.listdata2[261].shen=168
		this.listdata2[261].zoom=51

		this.listdata2[262].nian=27
		this.listdata2[262].shen=171
		this.listdata2[262].zoom=51

		this.listdata2[263].nian=21
		this.listdata2[263].shen=167
		this.listdata2[263].zoom=49
		
		this.listdata2[264].nian=23
		this.listdata2[264].shen=162
		this.listdata2[264].zoom=48
		
		this.listdata2[265].nian=27
		this.listdata2[265].shen=171
		this.listdata2[265].zoom=51

		this.listdata2[266].nian=30
		this.listdata2[266].shen=169
		this.listdata2[266].zoom=50

		this.listdata2[267].nian=32
		this.listdata2[267].shen=173
		this.listdata2[267].zoom=52	
		
		
		
		this.listdata2[268].nian=28
		this.listdata2[268].shen=153
		this.listdata2[268].zoom=42
		
	},
	  getRandomArbitrary(min, max) {
	   return (Math.random() * (max - min) + min).toFixed(1);
	 },
	 getRandomArbitrary2(min, max) {
	   return (Math.random() * (max - min) + min).toFixed(0);
	 },
	  
	 init3(){
	 	//index
	 	let xu=0
	 	let shen=0
	 	let index=0;
	 	let list=[]
		
		if(this.srh.length<=0){
			return;
		}
		

	 		for(let i=this.index_index;i<=(this.index_index+(this.page-1));i++){
	 			
	 	
	 			
	 		if(this.srh[i])
	 			list[i]=
	 				
	 				{
	 					
	 							  'name':this.srh[i].name ,
	 							  'bg':this.srh[i].bg ,
	 							  'nian':this.srh[i].nian,
	 							  'shen':this.srh[i].shen,
	 							  'zoom':this.srh[i].zoom,
	 							  xu:this.srh[i].xu,
								   sheng:this.srh[i].sheng,
								    shiqu:this.srh[i].shiqu,
								  
	 							  
	 				}
	 				
	 		
	 			
	 			
	 			
	 		}
				
	 	Object.keys(list).map(item=>{
	 			index=Number(item)+1
	 		
	 		 	//this.listdata[item].xu=index<=10?('00'+index):'0'+(index+1)
	 			if(this.left.length>this.right.length){
	 				this.right.push(list[item])
	 			}else if(this.left.length<this.right.length){
	 				this.left.push(list[item])
	 			}else{
	 				this.left.push(list[item])
	 			}		
	 			
	 			
	 		
	 		
	 	})
	 	this.index_index=this.index_index+this.page
	 	
	 	
	 
	 },
	 
	  
	init(){
		//index
		let xu=0
		let shen=0
		let index=0;
		let list=[]
	
			for(let i=this.index_index;i<=(this.index_index+(this.page-1));i++){
				
				
			 if(this.listdata2[i])	
				list[i]=
					
					{
						
								  'name':this.listdata2[i].name ,
								  'bg':this.listdata2[i].bg,
								  'nian':this.listdata2[i].nian,
								 'shen':this.listdata2[i].shen,
								 'zoom':this.listdata2[i].zoom,
								  xu:this.listdata2[i].xu,
								  sheng:this.listdata2[i].sheng,
								  shiqu:this.listdata2[i].shiqu,
								  
					}
					
				
				
				
				
			}
			
		Object.keys(list).map(item=>{
				index=Number(item)
				
			 	//this.listdata[item].xu=index<=10?('00'+index):'0'+(index+1)
				if(this.left.length>this.right.length){
					this.right.push(list[item])
				}else if(this.left.length<this.right.length){
					this.left.push(list[item])
				}else{
					this.left.push(list[item])
				}		
				
				
			
			
		})
		this.index_index=this.index_index+this.page
		
		
	}
  },
  
  
  
  created() {
    if (localStorage.getItem("token")) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "로그인 회원가입";
      this.userInfo.ip = "로그인하시면 더 많은 서비스를 즐기실 수 있습니다";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
	let index=0
	this.init2()
	this.init()
	

	
	
  },
  
};
</script>
<style lang="less" scoped>
	.page {
	    width: 100%;
	  
	    height: 100%;
	}
.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }

  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}


.mine {

  bottom: 10px;
  background: #f2f2f5;
 
height: 100% !important;
}
.wrapper{
	background-image:url('/img/login/bg.png');
	background-size: 100% 100%;
	   background-repeat: no-repeat;
	   width: 100%;
	   height: 100%;
	   padding-top: 8%;
}


.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	padding-top: 5%;
	position: relative;
}
.result{
	width: 84px;
	height: 90px;
}
.tx_top_title{
	position: absolute;
	 top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);

}
.yue{
	width: 95%;
	margin: 0 auto;
	background-image: url('/img/tx/yue.png');
	height: 7rem;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-top: 5%;
	display: flex;
	align-items: center;
	padding-left: 5%;
}
.jin_title{
	color: #fff;
}
.jin_jin{
	font-size: 52px;
	color: #fff;
	font-weight: 700;
	margin-top: 10%;
}
.ipu{
	width: 95%;
	margin: 0 auto;

	
	background: linear-gradient(90deg, #332348, #261D3E, #332244);
	border-radius: 8px;
	border: 2px solid #5E3876;
	
	margin-top: 5%;
	padding: 4%;
}
.cjp{
	color: #fff;
}
.yuan{
	background: #321B51;
	border-radius: 12px;
	border: 2px solid #BA68F3;
	width: 100%;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 3%;
	padding-right: 6%;
	margin-top: 5%;
}
.hs{
	display: flex;
	align-items: center;
	width: 30%;
	    justify-content: flex-end;
}
.ip_yue{
	background-color: initial !important; 
	height: 100%;
	border: 0;
	color: #999999;
	width: 70%;
}
.close{
	width: 40px;
	height: 40px;
	
}
.KRW{
	color: #fff;

}
.xian2{
	width: 2px;
	height: 100%;
	background-color: #999999;
	height: 1rem;
	margin-left: 10%;
	margin-right: 10%;
}
.ti{
	width: 95%;
	margin: 0 auto;
	background: #FF1A6D;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 3.5rem;
	margin-top: 5%;
}
.text{
	width: 95%;
	margin: 0 auto;
	margin-top: 8%;
	color: #FFFFFF;
	opacity: 0.5;
	font-size: 30px;
}
.rows{
	margin-bottom: 5%;
	margin-top: 5%;
	line-height: 2;
	padding-bottom: 20%;
}
.uu{
	margin-top: 5%;
	color: #b3aaba;
	font-size: 12px;
}
.duan{
	background-color: #FF1A6D;
	width: 8px;
	height: 40px;
	border-radius: 8px;
	margin-right: 3%;
}
.srf{
	display: flex;
	align-items: center;
}
.go{
	width: 15px;
	height: 20px;
}
.bank{
	display: flex;
	color: #fff;
	margin-top: 5%;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #523669;
	padding-bottom: 5%;
}
.bank2{
	border: 0;
}
.lis{
	margin-bottom: 30px;
}
.gs{
	width: 90%;
	margin: 0 auto;
	margin-top: 5%;
	display: flex;
	  flex-wrap: wrap;
	justify-content: space-between;
	height: 75%;
	overflow-y: scroll;
	
}
.right{
	margin-left: 5%;
}
.red{
	color: #FF1A6D;
	    font-size: 1rem;
		font-weight: 500;
}
.bai{
	color: #fff;
	font-size: 1rem;
	margin-top: 5%;
	margin-bottom: 5%;
}
.shuai{
	display: flex;
	margin-top: 8%;
}
.dian{
	display: flex;
	align-items: center;
	color: #fff;
	margin-left: 8%;
}
.dianshu{
	margin-right: 20px;
}
.right{
	width: 60%;
}
.shus{
	padding-right: 40%;
}
.left2{
	margin-top: 5%;
	display: flex;
	flex-direction: row;
	margin-left: 0;
}
.diansd{
	display: flex;
	align-items: center;
}
.name{
	color: #4f4260;
	font-size: 0.8rem;
	text-align: center;
}
.tt{
	color: #FFFFFF;
	margin-top: 15%;
	font-size: 0.9rem;
}
.li{
	width: 100%;
	margin-bottom: 8%;
	height: auto;
	font-size: 0;
}
.top{
	width:90%;
	height: 3.2rem;
	

	border-radius: 8px;
	padding-left: 3%;
	padding-right: 3%;
	background-image: url('/img/goods/top.png');
	
}
.serch{
	width: 35px;
	height: 35px;
}
.input{
	margin-left: 2%;
	background-color: initial !important;
	font-size: 23px !important;
}
.li{
	border-radius: 20rpx;
	border: 2px solid #C36DFE;
	border-radius: 12px;
	position: relative;
}
.img{
	width: 100%;
	
		border-radius: 10px;
}
.xuhao{
	background-color: #ff2a77;
	width: 3rem !important;
	height: 2rem !important;
	border-top-left-radius: 9px !important;
	font-size: 35px !important;
}
.a{
	color: #fff;
	font-size: 1.1rem;
	text-align: center;
}
.foot{
	margin-left: 0;
	left: 0;
}
.b{
	font-size: 1.3rem;
	text-align: center;
	margin-top: 0;
}
.c{
	color: #fff;
	background-color: #FF1A6D;
	
	width: 60%;
	font-size: 0.9rem;
	margin: 0 auto;
	margin-top: 5%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3%;
	padding-bottom: 3%;
	border-radius: 15px;
}
.select{
	    width: 90%;
	    margin: 0 auto;
		margin-top: 5%;
		display: flex;
		justify-content: space-between;
}
.se{
	width: 20%;
	display: flex;
	justify-content: center;
	border: none;
		outline: none;
		background: none;
		box-shadow: none;
		appearance:none;
		color: #fff;
		text-align: center;
		
		  text-align-last: center;
		font-size: 0.9rem;
}

.se option{
	text-align: center;
	 text-align-last: center;
	 background-color: #ccc;
}
.ps{
	width: 49%;
	
}
.my-list {
  height: 300px; /* 设置固定高度 */
  overflow-y: auto; /* 开启滚动 */
}
</style>
<style>
	.pp1 .van-field__control {
	  color: #fff; /* 红色字体 */
	}
	.pp2 .van-field__control{
		background-color:initial !important;
		font-size: 1rem;
	}
	.pp2 .van-search__content{
		background-color:initial !important;
	}
	.pp2 .van-icon-search{
		display: none !important;
	}
	
.pp34 .van-image__img{
		border-radius: 10px;
	}
</style>