<template>
  <div class="home-container page pp22 pcs">
   <div class="main">
      
      <!-- <div class="top">
           <img src="/img/home/nao.png" style="width:4rem;"/>




<marquee  style="width: 90%;">

	 	<div style="color:#FF1A6D;font-size: 0.8rem;">공지：{{notice}}</div>
 </marquee>

          
      </div> -->
	  
	  <swiper class="banner_swiper" :options="bannerSwiperOption">
	    <swiper-slide v-for="(v, key) in banners" :key="key">
	      <van-image class="banner_img" round :src="v.url">
	        <template v-slot:loading>
	          <van-loading type="circular" />
	        </template>
	      </van-image>
	    </swiper-slide>
	  </swiper>

    <div class="ju">

        <div class="txt">
          서비스 스타
        </div>

	<van-swipe class="my-swipe"  width="165"  :show-indicators="false" :loop="false">
	  <van-swipe-item  class="ss" v-for="(rows,num) in top_data">
		  
		  
		  <div class="gs">
			<img :src="rows.bg" class="goods"/>
			<div class="xuhao">0{{(num+1)<10?'0'+(num+1):10}}</div>
		  
			<div class="foot">
		  
				<div class="name">{{rows.name}}</div>
		  
				<div class="tt2">
					
					
					<img :src="rows.icon" class="yuan"/>
					<div class="sui">{{rows.sui}}세/{{rows.shengao}}cm/{{rows.zoom}}kg</div>
				
				</div>
		  
		  
			</div>
		  
		  
		   </div>
		  
		 
								   
								   
	  </van-swipe-item>
	  
	
	</van-swipe>

       

		
		
	
		
		
		
		
		
		
	
		
		
		
	


 


    




        <div class="txt">
          실시간 매칭
        </div>


 <div class="top top_l">
	 <!-- autoplay="1000"-->
	 	<van-swipe class="my-swipe swipe-vertical" autoplay="1000"  :vertical="true"   :show-indicators="false" width="100%"  style="height: 2.2rem;width: 100%;" >
			
			<van-swipe-item v-for="num in 50" style="display: flex;justify-content: space-between;">
				<div class="left">
				
				    <img :src="goods_iocn[num%36]" style="width:2.5rem;border-radius: 40%;border: 1px solid #3581FF;"/>
				    <div class="phone">{{goods_name[num%7]}}</div>
				
				</div>
                <div class="left">
                    <img src="/img/home/yuan.png" style="width:2rem;"/>
               <div class="red">매칭 성공</div>
                    </div>
				
			</van-swipe-item>
			
	
			
			
			
		</van-swipe>	
	 
        


        
      </div>


    <div class="txt">
          실시간 후기
        </div>
<!-- autoplay="1000" -->
	<van-swipe  class="my-swipe swipe-vertical"   :vertical="true"   :show-indicators="false" width="100%"  style="width: 100%;height: 250px;overflow: scroll;" height="80" >
			<van-swipe-item v-for="num in 20" style="margin-bottom: 3%;">
				
				<div class="top top_l" >
				         <div class="left" style="width:70%;height: 5rem;">
				
				              <img :src="goods_iocn[num%36]" style="width:3rem;height: 3.5rem;border-radius: 38%;border: 1px solid #3581FF;"/>
				              <div class="left left2" >
				                 <div class="a">@{{goods_name[ num%7]}}</div>
				                 <div class="b">메시지: {{Math.ceil(Math.random()*22)}}번호 {{pinglun[ Math.ceil(Math.random()*pinglun.length-1)]}}</div>
				              </div>
				
				         </div>
				
				
				           
				
				                  <div class="left left3">
				                   예약됨
								   <span class="dd3e">0{{20+Math.ceil(Math.random()*10)}}</span>
								   번호
				              </div>
				       
				     </div>
			</van-swipe-item>
		
		</van-swipe>	
	 


		   
	



    </div>







   </div>
  


    
      



  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: "받다......",
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
	  goods_img:[
		  
		'/img/home/1.png',
		'/img/home/11.png',
		'/img/home/22.png',
		'/img/home/201.png',
		'/img/home/gs.png',
		  
	  ],
	  goods_name2:[
	  		  '장 조심',
	  		  '두미미',
	  		  '장 연',
	  		  '리 리',
	  		  '조연자',
	  		  '서미령',
	  		  '장소가'
	  		  
	  ],
	  goods_iocn:[
	  		  
	  			'/img/nan/1.jpg',
				'/img/nan/2.jpg',
				'/img/nan/3.jpg',
				'/img/nan/4.jpg',
				'/img/nan/5.png',
				'/img/nan/6.png',
				'/img/nan/7.png',
				'/img/nan/8.png',
				'/img/nan/9.png',
				'/img/nan/10.png',
				'/img/nan/11.png',
	  			
				'/img/nan/12.png',
				'/img/nan/13.png',
				'/img/nan/14.png',
				'/img/nan/15.png',
				'/img/nan/16.png',
				'/img/nan/17.png',
				'/img/nan/18.png',
				'/img/nan/19.png',
				'/img/nan/20.png',
				'/img/nan/21.png',
				'/img/nan/22.png',
				
				
				'/img/nan/23.png',
				'/img/nan/24.png',
				'/img/nan/25.png',
				'/img/nan/26.jpg',
				'/img/nan/27.jpg',
				'/img/nan/28.jpg',
				'/img/nan/29.jpg',
				'/img/nan/30.png',
				'/img/nan/31.png',
				'/img/nan/32.png',
				'/img/nan/33.png',
				
	  				'/img/nan/34.png',
	  				'/img/nan/35.png',
	  				'/img/nan/36.png',
	  				'/img/nan/37.png',
	  				
	  				 
	  			  
	  			  
	  ],
	  'goods_name':[
	  		  'od***116',
	  		  'sz***d7',
	  		  'zzw***99',
	  		  'sh***005',
	  		  'cn***ei',
	  		  'hac***90',
	  		  'uui***256'
	  ],
	  'goods_zz':[
	  		  '강나영',
	  		  '강소라',
	  		  '강지영',
	  		  '강지원',
	  		  '김다희',
	  		  '김민정',
	  		  '김상미',
	  		  '민국'
	  		  
	  ],
	  'pinglun':[
	  	          '살면서 빨간색 딸기보지는 처음이엿어요 제여친보지는 거봉보지였네요.',
		  '은지 후장개통 내가함^^개이득.',
		  '여기가 최고의 방앗간 입니다 적극 추천.',
		  '후기쓰면서비스준대서작성.',
		  '님아그강을건너지마오 할아버지도 다시 유턴해서 온다는 보지맛집.',
		  '섹스10년만에해요.눈물흘려답니다.',
		  '60대여자도 추가해주새요 내취향.',
		  '개보지간나년들 임신시켜주갓어.',
		  '최고의 암 예방.',
		  '섹딱띠.',
		  '야~따 시부랄 맛잇구나야.',
		  '꼬추 터질뻔.',
		  '잠못자겠다.',
		  '혀에 모터달앗다 ;',
		  '마누라가짱인줄알았는데 ..',
		  '여기 인사 지대로네~',
		  '일씩씩하게보네~?',
		  '분수 터져버렸다..',
		  '살면서 이런여자 처음봄 넣자마자쌈.',
		  '맛있게 잘먹었습니다 .',
	  ],
	 
	  
	  'top_data':[
		  
		  {
			'bg':'/img/index_s/1.jpg',
			 'name':'',
			 'icon':'/img/index_s/11.jpg',
			 'sui':22,
			 'shengao':165,
			 'zoom':46
			  
		  },
		  {
		  			'bg':'/img/index_s/2.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/12.jpg',
					 'sui':26,
					 'shengao':170,
					 'zoom':53
		  			  
		  },
		  {
		  			'bg':'/img/index_s/3.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/13.jpg',
					 'sui':21,
					 'shengao':169,
					 'zoom':51
		  			  
		  },
		  {
		  			'bg':'/img/index_s/4.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/14.jpg',
					 'sui':23,
					 'shengao':165,
					 'zoom':49
		  			  
		  },
		  {
		  			'bg':'/img/index_s/5.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/15.jpg',
					 'sui':25,
					 'shengao':167,
					 'zoom':50
		  			  
		  },
		  {
		  			'bg':'/img/index_s/6.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/16.jpg',
					 'sui':22,
					 'shengao':162,
					 'zoom':47
		  			  
		  },
		  {
		  			'bg':'/img/index_s/7.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/17.jpg',
					 'sui':34,
					 'shengao':170,
					 'zoom':54
		  			  
		  },
		  {
		  			'bg':'/img/index_s/8.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/18.jpg',
					 'sui':30,
					 'shengao':166,
					 'zoom':49
		  			  
		  },
		  {
		  			'bg':'/img/index_s/9.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/19.jpg',
					 'sui':24,
					 'shengao':172,
					 'zoom':52
		  			  
		  },
		  {
		  			'bg':'/img/index_s/10.jpg',
		  			 'name':'',
		  			 'icon':'/img/index_s/20.jpg',
					 'sui':22,
					 'shengao':168,
					 'zoom':49
	  		  			  
	  		  }
	  		  
	  		  
	  ]
    };
  },
  methods: {
    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/PlayVideo?id=" + id });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("새로고침 성공");
      }, 500);
    },
    getVideoList() {
      this.$http({
        method: "get",
        data: { id: 0, page: 120 },
        url: "video_list",
      }).then((res) => {
        console.log(res.data.data);
        this.getMovieList_1(res.data.data); //获取首页视频1
      });
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        console.log("3", res);
        this.getNotice(this.basicData); //获取公告
        this.getBanner(this.basicData); //获取banner
        this.getGameItem(); //获取首页游戏列表
        this.getMovieList_0(this.basicData); //获取首页视频0
      });
    },

    getNotice(data) {
      console.log("data===🚀===>", data);
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        res.data.forEach((item, index) => {
          if (index === 0) {
            item.name = "데이트 이벤트1";
          } else if (index === 1) {
            item.name = "데이트 이벤트2";
          } else if (index === 2) {
            item.name = "데이트 이벤트3";
          } else if (index === 3) {
            item.name = "데이트 이벤트4";
          }
        });
        this.gameitem = res.data;
      });
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0;
    },
    getMovieList_1(data) {
      this.movielist_1 = data;
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    this.getBasicConfig();
    //this.getVideoList();
	

	
  },
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.folt {
  // overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  width: 170px;
}
.linear-bg {
  height: 200px;
  background: linear-gradient(90deg, #775fd9, #c24491);
}
.home-container {
  
}
.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner {
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .van-grid {
  justify-content: space-between;
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game {
  width: 100%;
  height: 100%;
}
.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div > div:first-child {
  width: 330px;
}
.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #c24491;
}
.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #c24491;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #c24491;
}
.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 7px;
  height: 28px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div {
  margin-top: -3px;
}
.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #c24491;
}
.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}
.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}
.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span {
  font-size: 30px;
}
.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 320px;
}
.home-scroll {
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
  flex:1;
  min-width: 48%;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 100%;
  bottom: 42px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
  padding: 0 20px;
}
.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

</style>
<style>
.main{
     
    width:100%;
  
  padding-top: 5%;
}

.top{
  display:flex;
  width:90%;
  margin:0 auto;
align-items: center;
    background-image: url('/img/home/in.png');
         background-size: 100% 100%;
         
}
.ju{
    width:90%;
  margin:0 auto;
}
.txt{

font-size: 30px;
color: #FFFFFF;
  margin-top: 5%;
  margin-bottom: 5%;

}
.goods{

  width:100%;
  height:100%;
  border-radius:13px;
  border:1.5px solid #c36dfe;
  position:relative;

}
.xuhao{
  background:#FF1A6D;
  color:#fff;
  position:absolute;
    width:80px;
   height:65px;
   display:flex;
   align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;

        border-bottom-right-radius: 15px;
        top:0;
}
.gs{
   position:relative;
       width: 100%;
    height: 15.1875rem;
	margin-right: 5%;
 
}
.foot{
  position:absolute;
  bottom:5%;
  width:100%;
  left:5%;
}
.name{
  color:#fff;
  font-size: 1.1rem;
      font-weight: 800;
          margin-bottom: 8%;
		
}
.tt2{
  display:flex;
  
}
.yuan{
  width:65px;
  height:65px;
  border: 2px solid #FF1A6D;
  border-radius: 47%;
}
.sui{
  color:#fff;
      margin-left: 2%;
      font-size: 25px;
      display:flex;
      align-items: center;
}
.top_l{
  display:flex;
  justify-content: space-between;
  padding-left: 5%;
    padding-right: 5%;
    width:104%;
        padding-top: 3%;
    padding-bottom: 3%;
	
}
.left{
   display:flex;
   
    align-items: center;
}
.phone{
  color:#fff;
 margin-left: 8%;
 font-size: 1rem;
}
.red{
  color:#ff1a6d;
      font-size: 25px;

}
.left2{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
   margin-left: 5%;
}
.left3{
  color:#fff;
  font-size: 0.75rem;
          height: 2.5rem;
    display: flex;
    align-items: flex-start;
}
.a{
  color:#FF1A6D;
   font-size: 1.2rem;
   
}
.b{
  color:#fff;
  font-size: 0.6rem;
   margin-top: 8%;
   font-weight: 600;
}
.txt_gs{
	
}
.ss{
	display: flex;
	
}
#app{
  height: 100%;	
}
.pcs{
	background-image: url('/img/login/bg.png');
	       background-size: 100% 100%;
	   background-repeat: no-repeat;
	   height: 100%;
	   overflow: scroll;
}
.dd3e{
	font-size: 35px;
}
</style>

