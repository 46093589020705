import Vue from 'vue'
import http from '../http';

import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Mine2 from '../pages/mine/index2.vue'/* 我的 */
// import Choose from '../pages/choose/index.vue'/* 选妃 */
// import List from '../pages/choose/list.vue'/* 选妃列表 */

// import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */

import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
// import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
// import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
//import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
// import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
// import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
// import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import ChongRecord from '../pages/mine/ChongRecord'/* 充值记录 */

import Tx from '../pages/mine/Tx'/* 绑定银行卡界面 */


import Chong from '../pages/mine/Chong'/* 绑定银行卡界面 */

import Yin from '../pages/mine/Yin'/* 绑定银行卡界面 */

import Chongadd from '../pages/mine/Chongadd'/* 绑定银行卡界面 */
import Youxi from '../pages/mine/Youxi'/* 绑定银行卡界面 */

import Goods from '../pages/mine/Goods'/* 绑定银行卡界面 */

Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: 'Kissmia' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: 'Kissmia' } },
    // { path: '/Choose', name: 'choose', component: Choose, meta: { title: 'Kissmia' } },
    // { path: '/List', name: 'list', component: List, meta: { title: 'Kissmia' } },
   
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: 'Kissmia' } },
	{ path: '/Mine2', name: 'mine2', component: Mine2, meta: { title: 'Kissmia' } },
    // { path: '/Video', name: 'video', component: Video, meta: { title: 'Kissmia' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: 'Kissmia' } },
	

    { path: '/Login', name: 'login', component: Login, meta: { title: 'Kissmia' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: 'Kissmia' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: 'Kissmia' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: 'Kissmia' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: 'Kissmia' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: 'Kissmia' } },
    // { path: '/Setname', name: 'Setname', component: Setname, meta: { title: 'Kissmia' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: 'Kissmia' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: 'Kissmia' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: 'Kissmia' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: 'Kissmia' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: 'Kissmia' } },
    // { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: 'Kissmia' } },
    // { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: 'Kissmia' } },
    // { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: 'Kissmia' } },
    // { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: 'Kissmia' } },
    { path: '/Tx', name: 'Tx', component: Tx, meta: { title: 'Kissmia' } },
	 { path: '/Chong', name: 'Chong', component: Chong, meta: { title: 'Kissmia' } },
	  { path: '/Yin', name: 'Yin', component: Yin, meta: { title: 'Kissmia' } },
     { path: '/Chongadd', name: 'Chongadd', component: Chongadd, meta: { title: 'Kissmia' } },
	   { path: '/Youxi', name: 'Youxi', component: Youxi, meta: { title: 'Kissmia' } },
  { path: '/Goods', name: 'Goods', component: Goods, meta: { title: 'Kissmia' } },


    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: 'Kissmia' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: 'Kissmia' } },
    // { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: 'Kissmia' } },

	{ path: '/ChongRecord', name: 'ChongRecord', component: ChongRecord, meta: { title: 'LOVEHAVEN' } },
];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    if (to.path === '/Login') {
        next()
    } else {
        try {
            let res = await http({
                method: "get",
                url: "user_info",
            })
            if (res.code === -1 && res.msg === '네트워크가 불안정합니다') {
                localStorage.clear();
                next('/Login')
            } else {
                next()
            }
        } catch (error) {
            next()
        }
    }

})

export default router