<template>
  <div class="mine page">


    <div class="page-bg"></div>
    <div class="wrapper">

      <div class="user_top">
          <van-image
              round
              class="user_img"
              :src="require('/public/img/nan/DM_20240714171637_001.jpg')"
            >
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>

               <p class="login-btn" style="color:#fff;">{{ userInfo.username }}</p>

               <div class="ro">
                <img src="/img/mine/huang.png" class="huang"/>
                  <div style="margin-left: 8px; font-size: 15px;">신용점수:{{userInfo.amount_code}}</div>
               </div>

      </div>

    <div class="but">
      <div class="chong" @click="/*doPay()*/ nato('Tx')">

<img src="/img/mine/2_2.png" class="tx_img" />

충전

      </div>

       <div class="tx" @click="doWithdrawal()">
       <img src="/img/mine/1_1.png" class="tx_img" />
인출

      </div>

    </div>

<div class="yue">
    <div>

          <div style="margin-bottom:10%;">
              보유 잔액

            </div>


              <div class="jiner">

                {{ userInfo.money }}
            </div>


    </div>


      <div style="display: flex;">
     
		<span class="font-28 font-gray" style="display: flex;align-items: center;">잔액(원)</span>
	               <div class="refresh-btn" @click="refresh()">
	                 <van-icon name="replay" />
	               </div>

    </div>



</div>

  <div class="menu">
      <div class="li">

          <div class="tt" @click="nato('SetLoginPassword')">
              <img src="/img/mine/icon/1.png"/>
              <div>암호변경</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>

    <div class="xian">


    </div>


  <!--       <div class="li" @click="zijin">

              <div class="tt">
              <img src="/img/mine/icon/2.png"/>
              <div>자금 암호</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>     -->
        <div class="xian">


    </div>
       <div class="li" @click="yin">

          <div class="tt">
              <img src="/img/mine/icon/3.png"/>
              <div>계정 세부정보</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>
        <div class="xian">


    </div>
       <div class="li" @click="youxi">

          <div class="tt">
              <img src="/img/mine/icon/4.png"/>
              <div>배팅 내역</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>
        <div class="xian">


    </div>
       <div class="li" @click="toNotice()">

          <div class="tt">
              <img src="/img/mine/icon/5.png"/>
              <div>공고</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>
        <div class="xian">


    </div>

      <div class="li"    @click="loginout()">

          <div class="tt">
              <img src="/img/mine/icon/6.png"/>
              <div>로그아웃</div>
          </div>

              <van-icon
                class="font-gray"
                style="font-size: 28px;"
              
                name="arrow"
              />
      </div>
  </div>

<div style="height:10rem;"></div>

     
    </div>
    <van-popup
      v-model="show"
      round
      @closed="form.amount = ''"
      :close-on-click-overlay="!payLoading"
    >
      <div class="pay-content">
        <div>
          <!-- 	<van-field readonly v-model="form.bank_number" style="margin-bottom: 20px" label="카드 번호"
						placeholder="카드번호를 입력해주세요" />
					<van-field readonly v-model="form.bank_name" style="margin-bottom: 20px" label="이름"
						placeholder="카드번호를 입력해주세요" /> -->
          <van-field
            v-model="form.amount"
            :readonly="payLoading"
            label="돈의 양"
            style="margin-bottom: 20px"
            placeholder="입금액을 입력해주세요"
          />
          <van-button
            round
            block
            type="info"
            :disabled="!form.amount"
            @click="pay"
            :loading="payLoading"
            loading-text="충전 중"
            >충전 중</van-button
          >
        </div>
      </div>
    </van-popup>


      










  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      show: false,
      payLoading: false,
      form: {
        bank_number: localStorage.getItem("bank_number"),
        bank_name: localStorage.getItem("bank_name"),
        amount: "",
      },
      menu_top: 40,
      isLoading: false,
    };
  },
  methods: {
	  loginout() {
	    localStorage.clear();
	    this.$router.push('Login');
	  },
    pay() {
      if (!this.form.amount) return this.$toast("금액을 입력해주세요.");
      this.payLoading = true;
      this.$http({
        method: "post",
        data: this.form,
        url: "recharge_apply",
      })
        .then((res) => {
          if (res.code === 200) {
            this.show = false;
            this.$toast("검토를 위해 제출됨");
          } else {
            this.show = false;
            this.$toast("고객 서비스에 문의하십시오");
          }
        })
        .catch(() => {
          this.show = false;
          this.$toast("고객 서비스에 문의하십시오");
        });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.$toast("다운로드 중");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    exit() {
      this.$toast("작업 목록을 작성하고 입력하세요.");
    },
    showSetting() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Setting",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    toNotice() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Notice",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.getUserInfo();
          this.$toast("새로고침 성공");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Infomation",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    doPay() {
      if (localStorage.getItem("token")) {
        this.show = true;
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
      // this.$toast("Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền");
      // return false;
    },
	nato(url){
		 this.$router.push(url);
	},
	zijin() {
		
	  this.$http({
	    method: "get",
	    url: "user_info",
	  }).then((res) => {
		 
	    if (res.data.paypassword) {
	      this.$toast.fail("출금 비밀번호가 설정되었습니다");
		 
	    } else {
	      this.$router.push("SetPayPassword");
	     
	    }
	  });
	},
	yin(){
		
		this.$http({
		  method: "get",
		  url: "user_info",
		}).then((res) => {
				
		  if (res.data.paypassword==null||res.data.name==null) {
		     this.$router.push("Chongadd");
				 
		  } else {
		    this.nato('Yin')
		   
		  }
		});
		
		
	},
	youxi(){
		
		this.$http({
		  method: "get",
		  url: "user_info",
		}).then((res) => {
				 
		  if (res.data.paypassword==null||res.data.name==null) {
		     this.$router.push("Youxi");
				 
		  } else {
				this.nato('Youxi')
		   
		  }
		});
		
	},
    doWithdrawal() {
		
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.data.is_bank) {
          this.$router.push("Chong");
		  //nato('Chong')"
		  
        } else {
			//Setbank
          this.$router.push("Chongadd");
          this.$toast.fail("포인트환급 은행을 등록해주세요");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("지원을 받으려면 고객 서비스에 문의하세요");
      }
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log("userInfo===🚀===>", this.userInfo);
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("오프라인 계정");
            localStorage.clear();
            this.$router.push({
              path: "/Login",
            });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "로그인 회원가입";
      this.userInfo.ip = "로그인하시면 더 많은 서비스를 즐기실 수 있습니다";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  },
};
</script>

<style lang="less" scoped>
.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }

  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
   background-image:url('/img/login/bg.png');
 background-size: 100% 100%;
    background-repeat: no-repeat;

}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.nav-bar {
  background: linear-gradient(90deg, #775fd9, #c24491);
}

.mine .header {
  background: linear-gradient(90deg, #775fd9, #c24491);
  padding-bottom: 100px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;
}

.mine .user_img {
  height: 120px;
  width: 120px;
  border:1px solid #ff1a6d;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0, 0%, 100%, 0.6);
}

.mine .page-bg {
  height: 500px;



}

.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
  color: #c24491;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 100px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
}

.font-gray {
  color: #fff;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 150px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 60px;
  color: #7e5678;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #c24491;
}
.ro{
  color:#fff;
background: linear-gradient(90deg, #5820FF, #FF1A6D);
border-radius: 20px;

display:flex;
justify-content: space-between;
padding-left: 3%;
    padding-right: 3%;
    align-items: center;
   font-size: 22px;
   padding-top: 1%;
    padding-bottom: 1%;
}
.user_top{
  width:92%;
  margin:0 auto;
  flex-direction: column;
  display:flex;
  justify-content: center;
      align-items: center;
         margin-top: 3%;
}
.huang{
  width:35px;
  height:30px;
 
  
}
.but{
  display:flex;
  width:92%;
  margin:0 auto;
  justify-content: space-between;
  margin-top: 5%;
    margin-bottom: 5%;
}
.chong{
  background-image: url('/img/mine/2.png');
  width:48%;
  background-size: 100% 100%;
   height:3.5rem;
   display:flex;
     justify-content: center;
        align-items: center;
            background-repeat: no-repeat;
            color:#fff;

}
.tx{
  background-image: url('/img/mine/1.png');
  width:48%;
   background-size: 100% 100%;
  height:3.5rem;
  display:flex;
    justify-content: center;
       align-items: center;

           background-repeat: no-repeat;
            color:#fff;
}
.tx_img{
  width:80px;
  height:80px;
   margin-right: 10%;

}
.yue{
   width:92%;
  margin:0 auto;
    background-image: url('/img/mine/yue.png');
       background-size: 100% 100%;
         height:6rem;
          margin-bottom: 2%;
          display:flex;
              justify-content: space-between;
                  align-items: center;
                  color:#fff;
                      padding-left: 3%;
    padding-right: 3%;
        padding-top: 4%;
    padding-bottom: 4%;

}
.jiner{
  
    font-size: 1.3rem;
}
.menu{
  width:92%;
  
  background:#22063A;
  margin:0 auto;
  margin-top:5%;
  border-radius:10px;
  display:flex;
      flex-direction: column;
      padding-left: 3%;
    padding-right: 3%;
  border:1px solid #C36DFE;
}
.li{
    display:flex;
        justify-content: space-between;
            align-items: center;
			padding-top: 2%;
          padding-bottom: 2%;
}
.tt{
  display:flex;
      align-items: center;
      color:#fff;
	  font-size: 0.9rem;
}
.tt img{
  width:85px;
  height:90px;
}
.xian{
  width:100%;
  height:2px;
  background:#fff;
  opacity: 0.12;
}
.refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #c24491;
}
</style>
