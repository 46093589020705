<template>
  <div class="bg-container page pp1">
   <img class="bg-img" src="../../../public/img/nv.jpg" />
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper" style="height: 7rem;">
              <img
                class="logo-img"
                src="../../../public/img/login/xin.png"
              />
            </div>
          </div>
          <div class="title">안녕하세요</div>
          <div class="loginForm">
           <van-field
             v-model="username"
             clearable
             input-align="left"
             class="input"
            autocomplete="off"
             placeholder="사용자 이름을 입력하세요. (영문 또는 숫자 6~12자)"
           />
		   
            <van-field
              v-model="password"
              :type="passwordType"
              input-align="left"
              class="input"
              placeholder="로그인 비밀번호(6~12자의 영문 또는 숫자)를 입력해주세요."
            >
              <template slot="right-icon">
                <van-icon
                  :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
                  @click="switchPasswordType"
                />
              </template>
            </van-field>
            <van-field
              v-model="code"
              clearable
              input-align="left"
              class="input"
              placeholder="초대코드를 입력해주세요"
            />
           <!-- <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text"
                >나는 "계좌 개설 계약"의 약관을 알고 있으며 이에
                동의합니다."</span
              >
            </div> -->
            <van-button
              class="login-btn"
              type="primary"
              size="normal"
              @click="doRegister()"
              >계정 등록</van-button
            >
              <div class="reg" @click="toLogin()">지금 로그인</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      checked: true,
      username: "",
      code: "",
      password: this.inputValue,
      passwordType: "password",
    };
  },
  methods: {
      toLogin() {
      this.$router.push("Login");
    },
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    doRegister() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast.fail("사용자 이름을 입력해주세요!");
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast.fail("비밀번호를 입력해주세요!");
        return false;
      }
      if (this.code === "" || this.code === null || this.code === undefined) {
        this.$toast.fail("초대코드를 입력해주세요!");
        return false;
      }
      // if (!this.checked) {
      //   this.$toast.fail(
      //     "Vui lòng đánh dấu vào thỏa thuận mở tài khoản bên dưới!"
      //   );
      //   return false;
      // }
      this.$http({
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          code: this.code,
        },
        url: "member_register",
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data);
          this.$router.push("Mine");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";

.register {
  height: 100%;
}
.bg-container .bg-wrapper .register .nav-bar {
  background: 0 0;
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
 position: absolute !important;;
 top: 0 !important;;
 left: 50% !important;
 transform: translateX(-50%) !important;
 width: 80% !important;;
 height: 95% !important;;
 -o-object-fit: contain;
 object-fit: contain;
}
.register .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
 margin-top: 2%;
      color:#ff1a6d;
  letter-spacing: 5px;
}
.register .wrapper .loginForm {
  padding: 60px;
}
.register .wrapper .loginForm .input {
  padding: 10px 20px;
  margin-top: 35px;
 
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #fff !important;;
  background-image: url('/img/login/in.png');
  background-color: inherit !important;
  background-size: 100% 100%;
}

::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .login-btn {
  margin-top: 30px;
  width: 100%;
  height: 110px;
  border-radius: 15px;
  color: #fff;
    background: #FF1A6D;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.register .wrapper .loginForm .agreement {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text {
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
.reg{
  color:#c4155e;
  font-weight: 800;
  margin-top: 8%;
  text-align: center;
  font-size: 1.1rem;


}

</style>
<style>
	.pp1 .van-field__control {
	  color: #fff; /* 红色字体 */
	}
</style>